export const environment = {
	production: true,
	legado: false,
	config: {
		oauth: {
			issuer: 'https://cognito-idp.sa-east-1.amazonaws.com/sa-east-1_HDVjEuucCs',
			redirectUri: window.location.origin + '/opini.one-avalie-ag-site/login',
			clientId: '',
			responseType: 'code',
			scope: 'aws.cognito.signin.user.admin openid profile email',
			strictDiscoveryDocumentValidation: false,
			disablePKCE: true,
		},
		apis: {
			v1: "https://api-hml.opini.one/v1",
			v2: "https://api-hml.opini.one/v2"
		},
	},
	tempoInavitividade: 2 * 60 * 1000
};
